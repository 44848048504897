@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .input {
        @apply focus: ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md p-2 ring-1 ring-slate-200 shadow-sm;
    }

    .btn {
        @apply font-bold bg-orange-700 px-4 py-2 rounded-md text-white focus:border-none items-center text-center;
    }
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}