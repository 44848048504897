.document {
    padding: 30px 15px 40px;

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
        color: #2c3e50;
    }
    h1 {
        font-size: 2.5rem;
    }
    h2 {
        font-size: 1.75rem;
        margin: 45px 0 0.8rem;
    }
    h3 {
        font-size: 1.5rem;
        margin-top: 16px;
    }
    h4 {
        font-size: 1.25rem;
    }
    h5 {
        font-size: 1rem;
    }
    h6 {
        font-size: 0.875rem;
    }
    p {
        font-size: 1rem;
        line-height: 1.6rem;
        word-spacing: 0.05rem;
        margin: 1.2em 0;
    }
    a {
        color: #e8671b;
        font-weight: 600;
        text-decoration: underline;
    }
    a:hover {
        color: #1a202c;
        text-decoration: none;
    }
    ul {
        padding-left: 1.5rem;
        line-height: 1.6rem;
        word-spacing: 0.05rem;
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    li {
        line-height: 1.6rem;
        word-spacing: 0.05rem;
    }
    hr {
        border: none;
        border-bottom: 1px solid #eee;
        margin: 2em 0;
    }
    blockquote {
        border-left: 4px solid #e8671b;
        color: #858585;
        margin: 2em 0;
        padding-left: 20px;
    }
    th, td {
        padding: 8px 12px 8px 12px;
        border: 2px solid #333;
    }
    tbody tr:nth-child(odd) {
        background-color: #fff;
    }
      
    tbody tr:nth-child(even) {
        background-color: #eee;
    }
    thead {
        background-color: #333;
        color: white;
        font-size: 0.875rem;
        text-transform: uppercase;
        letter-spacing: 2%;
    }
    pre {
        border: 1px solid #ddd;
        border-left: 3px solid #f36d33;
        color: #666;
        page-break-inside: avoid;
        font-family: monospace;
        font-size: 15px;
        line-height: 1.6;
        margin-bottom: 1.6em;
        max-width: 100%;
        overflow: auto;
        padding: 1em 1.5em;
        display: block;
        word-wrap: break-word;
    }
}